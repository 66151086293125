import React from 'react'
import {Card, Text, useToasts} from '@geist-ui/react'

import {SocketContext} from '../contexts/socket'
import {MixpanelContext} from '../contexts/mixpanel'

type props = {
  girl: girl
  other: girl
  nextSelection: () => void
}

type girl = {id: string, name: string, image: string, upvotes: number, downvotes: number}

export default function GirlCell(props: props) {
  const socket = React.useContext(SocketContext)
  const mixpanel = React.useContext(MixpanelContext)
  const [_toasts, setToast] = useToasts()

  function vote() {
    mixpanel.track('vote')
    socket.emit('decision', {upvote: props.girl.id, downvote: props.other.id})
    setToast({text: "Successful vote"})
  }

  return (
    <Card hoverable onClick={() => {vote(); props.nextSelection();}} width="100%" height="90%" style={{textAlign: 'center', cursor:'pointer'}}>
      <Text h3>{props.girl.name}</Text>
    </Card>
  )
}