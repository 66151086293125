import React from 'react'
import FJS from '@fingerprintjs/fingerprintjs'
import {SocketContext} from './contexts/socket'
import {MixpanelContext} from './contexts/mixpanel'
import GirlCell from './components/GirlCell'
import {isMobile} from 'react-device-detect';

import styled from 'styled-components'
import {Text, Dot, Grid, Modal, Table, useToasts} from '@geist-ui/react'

export default function App() {
  const socket = React.useContext(SocketContext)
  const mixpanel = React.useContext(MixpanelContext)

  const [authed, setAuthed] = React.useState(false)
  const [shutdown, setSD] = React.useState(false)
  const [choice, setChoice] = React.useState<null | any[]>(null)
  const [lbVisible, setVisible] = React.useState(false)
  const [leaders, setLeaders] = React.useState<any[]>([])

  const [_toasts, setToast] = useToasts()

  React.useEffect(() => {

    // key listener
    document.addEventListener("keydown", (e) => handleKeyDown(e))
    function handleKeyDown(e: KeyboardEvent) {
      if (e.code === "KeyS") {
        skip(); reqNext(); mixpanel.track('skip_choice');
      }
    }
     
    socket.on('connect', async () => {
      mixpanel.track('socket_connect')
      await authSocket()
      socket.on('security_shutdown', async () => setSD(true))
      socket.on('auth_success', async () => {
        mixpanel.track('auth_success')
        setAuthed(true)
        reqNext()
        socket.emit('req_leaders')
        socket.on('recv_leaders', async (data : any[]) => {
          console.log(data)
          setLeaders(data)
        })
      })

      socket.on('fail', () => {
        mixpanel.track('socket_fail')
      })
    })
  }, [])

  async function authSocket() {
    const fp = await FJS.load()
    const fingerprint = (await fp.get()).visitorId
    console.log(fingerprint)
    socket.emit('auth', fingerprint)
  }

  async function reqNext() {
    socket.emit('req_next')
    socket.on('recv_next', async (data) => {
      setChoice(data)
      if (!data) return setToast({text: 'Not able to fetch new choices', type: 'secondary'})
    })
  }
  
  async function skip() {
    if (choice) socket.emit('idk', [choice[0].id, choice[1].id])
  }

  return (
    <>
    {(shutdown ? (<h1>Unauthorized</h1>) : (
    <div style={{margin: 30}}>
      <Leaderboard visible={lbVisible} setVisible={setVisible} leaders={leaders} />
      <Header>Thunderhoes</Header>
      {leaders.length > 0 &&  <Text style={{color: 'blue', fontWeight: 600, cursor: 'pointer', marginTop: -15}} onClick={() => {setVisible(true); mixpanel.track('view_leaders')}}>View Leaderboard</Text>}
      {(choice && authed) &&
        <div>
          <Text h4 style={{marginTop: 10}}>Who's Hotter? Click to choose.</Text>
          <Grid.Container gap={1.5}>
            {choice.map(girl => (
              <Grid xs={12} key={girl.id} justify="center">
                <GirlCell girl={girl} other={choice.indexOf(girl) === 0 ? choice[1] : choice[0]} nextSelection={reqNext} />
              </Grid>
            ))}
          </Grid.Container>
          <Text style={{color: 'blue', fontWeight: 600, cursor: 'pointer'}} onClick={() => {skip(); reqNext(); mixpanel.track('skip_choice')}}>Skip (won't show again) {!isMobile && "(or press S on your keyboard)"}</Text>
        </div>
      }
    </div>
    ))}
    </>
  )
}

function Leaderboard(props: {visible: boolean, setVisible: (arg: boolean) => void, leaders: any[]}) {

  return (
    <Modal visible={props.visible} onClose={() => props.setVisible(false)}>
      <Modal.Title>Leaderboard</Modal.Title>
      <Modal.Content>
        {props.leaders &&
          <Table data={props.leaders}>
            <Table.Column prop="upvotes" label="votes" />
            <Table.Column prop="name" label="name" />
          </Table>
        }
      </Modal.Content>
    </Modal>
  )
}

const Header = styled.h2`
  font-weight: 700;
  background: -webkit-linear-gradient(45deg, #09009f, #00ff95 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
